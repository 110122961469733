/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import {
	StorageMap
} from '@ngx-pwa/local-storage';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	WindowEventConstants
} from '@shared/constants/window-event.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	IDrawerSettingStorage
} from '@shared/interfaces/application-objects/drawer-setting-storage';
import {
	ITimelineItem
} from '@shared/interfaces/application-objects/timeline-item.interface';
import {
	ICommonListSort
} from '@shared/interfaces/dynamic-interfaces/common-list-sort.interface';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	MenuItem
} from 'primeng/api';
import {
	Subject,
	debounceTime
} from 'rxjs';

/* eslint-enable max-len */

@Component({
	selector: 'app-history-timeline',
	templateUrl: './history-timeline.component.html',
	styleUrls: [
		'./history-timeline.component.scss'
	]
})

/**
 * A component representing an instance of the history timeline component.
 *
 * @export
 * @class HistoryTimelineComponent
 * @implements {OnInit}
 */
export class HistoryTimelineComponent
implements OnInit
{
	/** Creates a new instance of the history timeline component.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * Gets or sets the site layout service used for layout based displays
	 * in this component.
	 * @param {StorageMap} storageMap
	 * The local storage map used for setting storage.
	 * @memberof HistoryTimelineComponent
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService,
		protected storageMap: StorageMap)
	{
	}

	/**
	 * Gets or sets a value that signifies whether or not this should display
	 * in the minimum width view regardless of the page content size.
	 *
	 * @type {boolean}
	 * @memberof HistoryTimelineComponent
	 */
	@Input() public useMinimumWidth: boolean = false;

	/**
	 * Gets or sets a value that is able to override the timeline height used
	 * to calculate timeline distances.
	 * Note: This value is the $reservedHistoryDrawerHeight variable
	 * used in history-timeline.component.scss.
	 *
	 * @type {number}
	 * @memberof HistoryTimelineComponent
	 */
	@Input() public reservedPageHeight: number = 119.5;

	/**
	 * Gets or sets the set of timeline items associated to key dates
	 * in a timeline.
	 *
	 * @type {ITimelineItem[]}
	 * @memberof HistoryTimelineComponent
	 */
	@Input() public keyDateEvents: ITimelineItem[] = [];

	/**
	 * Gets or sets the set of timeline items associated to key activities
	 * in a timeline.
	 *
	 * @type {ITimelineItem[]}
	 * @memberof HistoryTimelineComponent
	 */
	@Input() public keyActivityEvents: ITimelineItem[] = [];

	/**
	 * Gets or sets the set of timeline items associated to data changes
	 * in a timeline.
	 *
	 * @type {ITimelineItem[]}
	 * @memberof HistoryTimelineComponent
	 */
	@Input() public changeEvents: ITimelineItem[] = [];

	/**
	 * Gets or sets the event that will be emitted to all listening components
	 * on the current selected timeline item.
	 *
	 * @type {EventEmitter<ITimelineItem>}
	 * @memberof HistoryTimelineComponent
	 */
	@Output() public itemSelected: EventEmitter<ITimelineItem> =
		new EventEmitter<ITimelineItem>();

	/**
	 * Gets or sets the element reference for the set of button bars.
	 *
	 * @type {ElementRef}
	 * @memberof HistoryTimelineComponent
	 */
	@ViewChild('HistoryTimelineHeader', { read: ElementRef })
	public historyTimelineHeader: ElementRef;

	/**
	 * Gets or sets a value that signifies whether the component is loading.
	 *
	 * @type {boolean}
	 * @memberof HistoryTimelineComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets a value that signifies whether the component storage is
	 * loaded.
	 *
	 * @type {boolean}
	 * @memberof HistoryTimelineComponent
	 */
	public storageLoaded: boolean = true;

	/**
	 * Gets or sets the observer of form validation changes.
	 *
	 * @type {Subject<void>}
	 * @memberof HistoryTimelineComponent
	 */
	public siteLayoutChange: Subject<void> = new Subject<void>();

	/**
	 * Gets or sets the set of timeline items to display in the timeline.
	 *
	 * @type {ITimelineItem[]}
	 * @memberof HistoryTimelineComponent
	 */
	public filteredEvents: ITimelineItem[] = [];

	/**
	 * Gets or sets a value that signifies whether we are showing the key
	 * dates data.
	 *
	 * @type {boolean}
	 * @memberof HistoryTimelineComponent
	 */
	public keyDatesDisplayed: boolean = false;

	/**
	 * Gets or sets a value that signifies whether we are showing the key
	 * activities data.
	 *
	 * @type {boolean}
	 * @memberof HistoryTimelineComponent
	 */
	public keyActivitiesDisplayed: boolean = false;

	/**
	 * Gets or sets a value that signifies whether we are showing the changes
	 * data.
	 *
	 * @type {boolean}
	 * @memberof HistoryTimelineComponent
	 */
	public changesDisplayed: boolean = false;

	/**
	 * Gets or sets the set of quick filter menu items to display in the
	 * timeline quick filter.
	 *
	 * @type {MenuItem[]}
	 * @memberof HistoryTimelineComponent
	 */
	public quickFilterMenuItems: MenuItem[] = [];

	/**
	 * Gets or sets the set of currently enabled filters for the timeline.
	 *
	 * @type {MenuItem[]}
	 * @memberof HistoryTimelineComponent
	 */
	public enabledFilters: MenuItem[] = [];

	/**
	 * Gets or sets the selected sorter for the timeline.
	 *
	 * @type {ICommonListSort}
	 * @memberof HistoryTimelineComponent
	 */
	public selectedSorter: ICommonListSort =
		{
			name: 'Change Date',
			value: AppConstants.commonProperties.changeDate,
			direction: AppConstants.sortDirections.ascending,
			iconAsc: AppConstants.cssClasses.sortAscending,
			iconDesc: AppConstants.cssClasses.sortDescending,
		};

	/**
	 * Gets or sets the storage settings for the timeline.
	 *
	 * @type {IDrawerSettingStorage}
	 * @memberof HistoryTimelineComponent
	 */
	private storageSettings: IDrawerSettingStorage;

	/**
	 * Gets a value that identifies and labels the key dates in the timeline.
	 *
	 * @type {string}
	 * @memberof HistoryTimelineComponent
	 */
	private readonly keyDatesIdentifier: string = 'Key Dates';

	/**
	 * Gets a value that identifies and labels the changes in the timeline.
	 *
	 * @type {string}
	 * @memberof HistoryTimelineComponent
	 */
	private readonly changesIdentifier: string = 'Changes';

	/**
	 * Gets a value that identifies the css selector for timeline elements.
	 *
	 * @type {string}
	 * @memberof HistoryTimelineComponent
	 */
	private readonly timelineEventElementIdentifier: string =
		'.p-timeline-event';

	/**
	 * Gets a value that sets the minimum event element height for time
	 * based differential displays.
	 *
	 * @type {number}
	 * @memberof HistoryTimelineComponent
	 */
	private readonly minimumEventElementHeight: number =
		35;

	/**
	 * Gets a value that represents this component name in storage.
	 *
	 * @type {string}
	 * @memberof HistoryTimelineComponent
	 */
	private readonly localStorageKey: string =
		'HistoryTimelineComponentSettings';

	/**
	 * Handles the site layout change event which is called
	 * when the site layout service has altered it's variables.
	 *
	 * @memberof HistoryTimelineComponent
	 */
	@HostListener(
		AppEventConstants.siteLayoutChangedEvent)
	public siteLayoutChanged(): void
	{
		this.siteLayoutChange.next();
	}

	/**
	 * Handles the window unload event which is called when the browser is
	 * refreshed. This will ensure storage settings are saved asynchronously
	 * on an F5 refresh.
	 *
	 * @memberof HistoryTimelineComponent
	 */
	@HostListener(
		WindowEventConstants.beforeUnloadEvent)
	public async beforeUnloadHandler()
	{
		await this.ngOnDestroy();
	}

	/**
	 * Implements the on initialization interface.
	 * This method will create data needed for the timeline display.
	 *
	 * @async
	 * @memberof HistoryTimelineComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.quickFilterMenuItems =
			[
				<MenuItem>
				{
					label: this.keyDatesIdentifier,
					title: this.keyDatesIdentifier,
					icon: AppConstants.cssClasses.fontAwesomeCalendar,
					command: (actionEvent: any) =>
						this.handleAddFilterEvent(
							actionEvent.item.label)
				},
				<MenuItem>
				{
					label: this.changesIdentifier,
					title: this.changesIdentifier,
					icon: AppConstants.cssClasses.fontAwesomeHistory,
					command: (actionEvent: any) =>
						this.handleAddFilterEvent(
							actionEvent.item.label)
				}
			];

		this.siteLayoutChange.pipe(
			debounceTime(this.siteLayoutService.debounceDelay * 1.5))
			.subscribe(
				() =>
				{
					this.calculateTimelineHeights();
				});

		await this.loadStorageSettings();

		this.filterEvents();

		this.loading = false;
	}

	/**
	 * Handles the on destroy event.
	 * This will complete save storage settings and handle a site layout
	 * change.
	 *
	 * @async
	 * @memberof HistoryTimelineComponent
	 */
	public async ngOnDestroy(): Promise<void>
	{
		this.siteLayoutChange.complete();

		if (this.storageLoaded === true)
		{
			await this.saveStorageSettings(
				[...this.enabledFilters],
				{...this.selectedSorter},
				this.storageMap,
				this.localStorageKey);
		}
	}

	/**
	 * Handles the item selection event.
	 * This will emit the selected item to all listening components.
	 *
	 * @param {ITimelineItem} item
	 * The selected timeline item.
	 * @memberof HistoryTimelineComponent
	 */
	public selectItem(
		item: ITimelineItem): void
	{
		if (item.icon?.indexOf(
			AppConstants.cssClasses.fontAwesomeCalendar) > -1)
		{
			return;
		}

		this.itemSelected.emit(item);
	}

	/**
	 * Handles the search text based event.
	 * This will filter out timeline items that do not have a matching value
	 * in the summary.
	 *
	 * @param {HTMLInputElement} element
	 * The input element to get the value from.
	 * @memberof HistoryTimelineComponent
	 */
	public handleSearchTextAdd(
		element: HTMLInputElement): void
	{
		const inputValue: string = element.value;

		if (AnyHelper.isNullOrWhitespace(inputValue))
		{
			element.focus();

			return;
		}

		this.enabledFilters =
			this.enabledFilters.filter(
				(item: MenuItem) =>
					!(item.label.toLowerCase() === inputValue.toLowerCase()
						&& AnyHelper.isNullOrWhitespace(item.icon)));

		this.enabledFilters.push(
			{
				label: inputValue
			});

		element.value = null;

		this.filterEvents();
	}

	/**
	 * Handles the add filter event.
	 * This will handle adding and removing values based on the quick filter
	 * selection.
	 *
	 * @param {string} filterIdentifier
	 * The quick filter identifier to add.
	 * @memberof HistoryTimelineComponent
	 */
	public handleAddFilterEvent(
		filterIdentifier: string): void
	{
		const quickFilters: string =
			[
				this.keyDatesIdentifier,
				this.changesIdentifier
			].join(AppConstants.characters.comma);

		if (quickFilters.indexOf(filterIdentifier) !== -1)
		{
			this.keyDatesDisplayed = false;
			this.keyActivitiesDisplayed = false;
			this.changesDisplayed = false;

			this.enabledFilters =
				this.enabledFilters.filter(
					(item: MenuItem) =>
						AnyHelper.isNullOrWhitespace(item.title)
							|| !quickFilters.includes(item.title));
		}

		this.enabledFilters.push(
			this.quickFilterMenuItems.find(
				(item: MenuItem) =>
					item.title === filterIdentifier));

		this.filterEvents();
	}

	/**
	 * Handles the remove filter event.
	 * This will remove the enabled filter based on the filter identifier.
	 *
	 * @param {string} filterIdentifier
	 * The filter identifier to remove.
	 * @param {string} icon
	 * The icon associated with the filter.
	 * @memberof HistoryTimelineComponent
	 */
	public handleRemoveFilterEvent(
		filterIdentifier: string,
		icon: string): void
	{
		this.enabledFilters =
			this.enabledFilters.filter(
				(item: MenuItem) =>
					AnyHelper.isNullOrWhitespace(icon)
						? item.label !== filterIdentifier
							|| !AnyHelper.isNullOrWhitespace(item.icon)
						: item.label !== filterIdentifier
							|| item.icon !== icon);

		this.filterEvents();
	}

	/**
	 * Handles the sort change event.
	 * This will change the sort direction of the timeline items.
	 *
	 * @memberof HistoryTimelineComponent
	 */
	public handleSortChangeEvent(): void
	{
		this.selectedSorter.direction =
			this.selectedSorter.direction ===
				AppConstants.sortDirections.ascending
				? AppConstants.sortDirections.descending
				: AppConstants.sortDirections.ascending;

		const filteredEvents =
			this.filteredEvents.map(
				(item: ITimelineItem) =>
				{
					item.height = this.minimumEventElementHeight;

					return item;
				});
		filteredEvents.reverse();

		this.filteredEvents =
			[
				...filteredEvents
			];

		this.siteLayoutChange.next();
	}

	/**
	 * Handles the sort direction event after loading filtered events.
	 *
	 * @memberof HistoryTimelineComponent
	 */
	public handleSortDirection(): void
	{
		if (this.selectedSorter.direction ===
			AppConstants.sortDirections.ascending)
		{
			return;
		}

		this.filteredEvents.reverse();

		this.filteredEvents =
			[
				...this.filteredEvents
			];
	}

	/**
	 * Filters the events based on the menu item selected.
	 *
	 * @memberof HistoryTimelineComponent
	 */
	public filterEvents(): void
	{
		let filteredEvents = [];

		if (this.enabledFilters.length === 0)
		{
			filteredEvents =
				this.keyDateEvents
					.concat(this.keyActivityEvents)
					.concat(this.changeEvents);
		}
		else
		{
			const searchFilters: string[] = [];
			for (const menuItem of this.enabledFilters)
			{
				switch(menuItem.title)
				{
					case this.keyDatesIdentifier:
						this.keyDatesDisplayed = true;
						this.keyActivitiesDisplayed = true;
						break;
					case this.changesIdentifier:
						this.changesDisplayed = true;
						break;
				}

				switch(menuItem.label)
				{
					case this.keyDatesIdentifier:
					case this.changesIdentifier:
						if (AnyHelper.isNullOrWhitespace(menuItem.title))
						{
							searchFilters.push(menuItem.label);
						}
						break;
					default:
						searchFilters.push(menuItem.label);
				}
			}

			filteredEvents =
				this.getFilteredEvents(
					searchFilters);
		}

		this.filteredEvents =
			filteredEvents
				.map(
					(item: ITimelineItem) =>
					{
						item.icon =
							item.icon ??
								AppConstants.cssClasses.fontAwesomeCalendar;

						return item;
					})
				.sort(
					(itemOne: ITimelineItem, itemTwo: ITimelineItem) =>
						itemOne.dateTime.toMillis()
							- itemTwo.dateTime.toMillis());

		this.handleSortDirection();
		this.siteLayoutChange.next();
	}

	/**
	 * Toggles the filter based on the menu item selected.
	 *
	 * @param {string[]} searchFilters
	 * The set of string based search filters.
	 * @returns {ITimelineItem[]}
	 * The filtered events based on the toggle.
	 * @memberof HistoryTimelineComponent
	 */
	private getFilteredEvents(
		searchFilters: string[]):
		ITimelineItem[]
	{
		let filteredEvents = [];

		if (this.keyDatesDisplayed === true)
		{
			filteredEvents =
				filteredEvents
					.concat(this.keyDateEvents);
		}

		if (this.keyActivitiesDisplayed === true)
		{
			filteredEvents =
				filteredEvents
					.concat(this.keyActivityEvents);
		}

		if (this.changesDisplayed === true)
		{
			filteredEvents =
				filteredEvents
					.concat(this.changeEvents);
		}

		return searchFilters.length === 0
			? filteredEvents
			: filteredEvents.filter(
				(item: ITimelineItem) =>
				{
					let matchesFilter: boolean = true;
					for (const filter of searchFilters)
					{
						if (matchesFilter === false)
						{
							break;
						}

						matchesFilter =
							item.summary.toLowerCase()
								.includes(filter.toLowerCase());
					}

					return matchesFilter;
				});
	}

	/**
	 * Calculates the timeline heights based on the filtered events.
	 *
	 * @memberof HistoryTimelineComponent
	 */
	private calculateTimelineHeights(): void
	{
		if (this.filteredEvents.length < 2)
		{
			return;
		}

		this.reservedPageHeight =
			this.historyTimelineHeader.nativeElement
				.getBoundingClientRect().height
				+ 36
				+ 14;

		const scrollPanel: HTMLDivElement =
			document.querySelector(
				'.history-timeline-scroll-content');

		if (AnyHelper.isNull(scrollPanel))
		{
			return;
		}

		scrollPanel.style.height =
			(this.siteLayoutService.windowHeight - this.reservedPageHeight)
				+ 'px';

		const totalTimelineLength: number =
			this.filteredEvents[
				this.filteredEvents.length - 1].dateTime.toMillis()
				- this.filteredEvents[0].dateTime.toMillis();

		const availableHeight: number =
			this.siteLayoutService.windowHeight
				- this.reservedPageHeight;
		const reservedHeight: number =
			this.getFinalElementsReservedHeight(
				availableHeight,
				totalTimelineLength);

		let initialItemAdded: boolean = false;
		let previousEvent: ITimelineItem = this.filteredEvents[0];
		const filteredEvents: ITimelineItem[] =
			[
				...this.filteredEvents
					.map(
						(item: ITimelineItem) =>
						{
							const heightPercent: number =
								(item.dateTime.toMillis()
									- this.filteredEvents[0]
										.dateTime.toMillis())
									/ totalTimelineLength;
							let expectedHeight: number =
								availableHeight * heightPercent;

							if (expectedHeight > reservedHeight)
							{
								expectedHeight =
									Math.max(
										reservedHeight,
										previousEvent.height
											+ this.minimumEventElementHeight);
							}
							else if (initialItemAdded === true
								&& (previousEvent.height
									+ this.minimumEventElementHeight) >
										expectedHeight)
							{
								expectedHeight =
									previousEvent.height
										+ this.minimumEventElementHeight;
							}

							item.height = expectedHeight;
							initialItemAdded = true;
							previousEvent = item;

							return item;
						})
			];

		const timelineEvents: HTMLDivElement[] =
			Array.from(
				document.querySelectorAll(
					this.timelineEventElementIdentifier));

		timelineEvents.forEach(
			(_item: HTMLDivElement,
				index: number) =>
			{
				if (index === 0)
				{
					return;
				}

				timelineEvents[index - 1].style.minHeight =
					(filteredEvents[index].height
						- filteredEvents[index -1].height)
					+ 'px';
			});

		this.filteredEvents =
			filteredEvents;
	}

	/**
	 * Gets the final elements reserved height based on the available height
	 * and the total timeline length.
	 *
	 * @param {number} availableHeight
	 * The available height for the timeline.
	 * @param {number} totalTimelineLength
	 * The total length of the timeline.
	 * @returns {number}
	 * The final elements reserved height.
	 * @memberof HistoryTimelineComponent
	 */
	private getFinalElementsReservedHeight(
		availableHeight: number,
		totalTimelineLength: number): number
	{
		let previousHeight: number = 0;
		const expectedFinalEvents: number =
			this.filteredEvents
				.filter(
					(item: ITimelineItem,
						index: number) =>
					{
						const remainingItems: number =
							this.filteredEvents.length - index;

						const heightPercent: number =
							(item.dateTime.toMillis()
								- this.filteredEvents[0]
									.dateTime.toMillis())
								/ totalTimelineLength;

						let expectedHeight: number =
							heightPercent * availableHeight;

						if (expectedHeight > 0
							&& (previousHeight
								+ this.minimumEventElementHeight) >
									expectedHeight)
						{
							expectedHeight =
								previousHeight
									+ this.minimumEventElementHeight;
						}

						previousHeight = expectedHeight;

						return expectedHeight
							+ (remainingItems * this.minimumEventElementHeight)
							>= availableHeight;
					}).length;

		return availableHeight
			- (expectedFinalEvents * this.minimumEventElementHeight);
	}

	/**
	 * Loads the storage settings for the timeline.
	 *
	 * @async
	 * @returns {Promise<void>}
	 * A promise that resolves when the storage settings are loaded.
	 * @memberof HistoryTimelineComponent
	 */
	private async loadStorageSettings(): Promise<void>
	{
		return new Promise(
			(resolve: any) =>
			{
				this.storageMap.get(this.localStorageKey)
					.subscribe(
						(storageSettings: IDrawerSettingStorage) =>
						{
							this.storageSettings =
								storageSettings
									?? <IDrawerSettingStorage>
										{
											enabledFilters: [],
											selectedSorter: this.selectedSorter
										};
							this.enabledFilters =
									this.deserializeFilters()
										?? [];
							this.selectedSorter =
								this.storageSettings?.selectedSorter
									?? this.selectedSorter;

							this.storageLoaded = true;

							resolve();
						});
			});
	}

	/**
	 * Saves the storage settings for the timeline. This async method can be
	 * called without await for a non-asynchronous but guaranteed to finish
	 * method.
	 *
	 * @async
	 * @param {IDrawerSettingStorage} storageSettings
	 * The settings storage to be saved.
	 * @returns {Promise<void>}
	 * A promise that resolves when the storage settings are saved.
	 * @memberof HistoryTimelineComponent
	 */
	private async saveStorageSettings(
		enabledFilters: MenuItem[],
		selectedSorter: ICommonListSort,
		storageMap: StorageMap,
		localStorageKey: string): Promise<void>
	{
		const storageSettings: IDrawerSettingStorage =
			<IDrawerSettingStorage>
			{
				enabledFilters:
					enabledFilters.map(
						(filter: MenuItem) =>
						{
							if (!AnyHelper.isNull(filter.command))
							{
								filter.serializedCommand =
									filter.command.toString();
								delete filter.command;
							}

							return filter;
						}),
				selectedSorter: selectedSorter
			};

		return new Promise(
			(resolve: any) =>
			{
				storageMap.set(
					localStorageKey,
					storageSettings)
					.subscribe(
						() =>
						{
							resolve();
						});
			});
	}

	/**
	 * Deserializes the filters for the timeline.
	 *
	 * @returns {MenuItem[]}
	 * The deserialized filters.
	 * @memberof HistoryTimelineComponent
	 */
	private deserializeFilters(): MenuItem[]
	{
		return this.storageSettings
			?.enabledFilters
			?.map(
				(filter: MenuItem) =>
				{
					if (!AnyHelper.isNull(filter.serializedCommand))
					{
						filter.command =
								new Function(
									'return '
										+ filter.serializedCommand)();
						delete filter.serializedCommand;
					}

					return filter;
				});
	}
}