/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Location
} from '@angular/common';
import {
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	DisplayComponentParameterDirective
} from '@shared/directives/display-component-parameter.directive';
import {
	DisplayComponentFactory
} from '@shared/factories/display-component-factory';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	DisplayComponentInstance
} from '@shared/implementations/display-components/display-component-instance';
import {
	IDashboardParameterSet
} from '@shared/interfaces/application-objects/dashboard-parameter-set';
import {
	IDashboardSection
} from '@shared/interfaces/application-objects/dashboard-section.interface';
import {
	IDashboardWidget
} from '@shared/interfaces/application-objects/dashboard-widget.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDisplayComponentContainer
} from '@shared/interfaces/display-components/display-component-container.interface';
import {
	IUser
} from '@shared/interfaces/users/user.interface';
import {
	DisplayComponentService
} from '@shared/services/display-component.service';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SessionService
} from '@shared/services/session.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	MenuItem
} from 'primeng/api';
import {
	debounceTime,
	distinctUntilChanged
} from 'rxjs';

/* eslint-enable max-len */

@Component({
	selector: 'app-common-dashboard',
	templateUrl: './common-dashboard.component.html',
	styleUrls: [
		'./common-dashboard.component.scss'
	],
	animations: [
		ContentAnimation
	]
})

/**
 * A component representing an instance of the common dashboard
 * component.
 *
 * @export
 * @class CommonDashboardComponent
 * @extends {DisplayComponentParameterDirective}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
export class CommonDashboardComponent
	extends DisplayComponentParameterDirective
	implements OnInit, OnDestroy
{
	/**
	 * Initializes a new instance of the CommonDashboardComponent.
	 * This component is used to display dynamic content based
	 * dashboard content.
	 *
	 * @param {Location} location
	 * The Angular common location service used for url interaction.
	 * @param {ModuleService} moduleService
	 * The module service used in this component.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this component.
	 * @param {SessionService} sessionService
	 * The session service used in this component.
	 * @param {DisplayComponentService} displayComponentService
	 * The service used to load and gather display component data.
	 * @param {DisplayComponentFactory} displayComponentFactory
	 * The factory used to generate display component interfaces.
	 * @param {ResolverService} resolver
	 * The resolver service used for display component providers.
	 * @memberof CommonDashboardComponent
	 */
	public constructor(
		public location: Location,
		public moduleService: ModuleService,
		public siteLayoutService: SiteLayoutService,
		public sessionService: SessionService,
		public displayComponentService: DisplayComponentService,
		public displayComponentFactory: DisplayComponentFactory,
		public resolver: ResolverService)
	{
		super(
			siteLayoutService,
			displayComponentService,
			displayComponentFactory);
	}

	/**
	 * Gets or sets the formly field.
	 *
	 * @type {FormlyFieldConfig}
	 * @memberof CommonDashboardComponent
	 */
	@Input() public field: FormlyFieldConfig;

	/**
	 * Gets or sets the display component instance name for
	 * this dashboard.
	 *
	 * @type {string}
	 * @memberof CommonDashboardComponent
	 */
	@Input() public dashboardDisplayComponentInstanceName: string;

	/**
	 * Gets or sets the serialized route parameters for this dashboard.
	 *
	 * @type {IDashboardParameterSet}
	 * @memberof CommonDashboardComponent
	 */
	@Input() public routeParameters: IDashboardParameterSet =
		<IDashboardParameterSet>{};

	/**
	 * Gets or sets the entity context.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof CommonDashboardComponent
	 */
	@Input() public entityContext: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the use route url.
	 *
	 * @type {boolean}
	 * @memberof CommonDashboardComponent
	 */
	@Input() public useRouteUrl: boolean = true;

	/**
	 * Gets or sets the full page dashboard setting.
	 *
	 * @type {boolean}
	 * @memberof CommonDashboardComponent
	 */
	@Input() public fullPageDashboard: boolean = false;

	/**
	 * Gets or sets finished loading emitt event.
	 *
	 * @type {EventEmitter<boolean>}
	 * @memberof CommonDashboardComponent
	 */
	@Output() public finishedLoading: EventEmitter<boolean> =
		new EventEmitter;

	/**
	 * Gets or sets header context content emitt event.
	 *
	 * @type {EventEmitter<object>}
	 * @memberof CommonDashboardComponent
	 */
	@Output() public headerContextContent: EventEmitter<object> =
		new EventEmitter;

	/**
	 * Gets or sets update route data emitt event.
	 *
	 * @type {EventEmitter<boolean>}
	 * @memberof CommonDashboardComponent
	 */
	@Output() public updateRouteData: EventEmitter<boolean> =
		new EventEmitter;

	/**
	 * Gets or sets the number of authorized sections that this
	 * dashboard is expected to display.
	 *
	 * @type {number}
	 * @memberof CommonDashboardComponent
	 */
	public authorizedSectionCount: number;

	/**
	 * Gets or sets the value that signifies that all sections and
	 * widgets have been displayed.
	 *
	 * @type {boolean}
	 * @memberof CommonDashboardComponent
	 */
	public dashboardDisplayComplete: boolean = false;

	/**
	 * Gets or sets number of currently loaded and displayed sections.
	 *
	 * @type {number}
	 * @memberof CommonDashboardComponent
	 */
	public displayedSectionCount: number = 0;

	/**
	 * Gets or sets the array of dashboard sections to display.
	 *
	 * @type {IDashboardSection[]}
	 * @memberof CommonDashboardComponent
	 */
	public dashboardSections: IDashboardSection[] = [];

	/**
	 * Gets or sets the dashboard type.
	 *
	 * @type {string}
	 * @memberof CommonDashboardComponent
	 */
	public dashboardType: string;

	/**
	 * Gets or sets the display component instance for this dashboard.
	 *
	 * @type {DisplayComponentInstance}
	 * @memberof CommonDashboardComponent
	 */
	public displayComponentInstance: DisplayComponentInstance;

	/**
	 * Gets or sets the parameter layout schema for this dashboard.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof CommonDashboardComponent
	 */
	public parameterLayoutSchema: FormlyFieldConfig[];

	/**
	 * Gets or sets the parameter layout data for this dashboard.
	 *
	 * @type {any}
	 * @memberof CommonDashboardComponent
	 */
	public parameterLayoutData: any;

	/**
	 * Gets or sets the user type group to allow this to be available
	 * in the page context source.
	 *
	 * @type {string}
	 * @memberof CommonDashboardComponent
	 */
	public sessionUserTypeGroup: string = 'Users';

	/**
	 * Gets or sets the user associated with this dashboard to make
	 * this available in the page context source.
	 *
	 * @type {IUser}
	 * @memberof CommonDashboardComponent
	 */
	public sessionUser: IUser;

	/**
	 * Handles the redraw dashboard event sent from the event emitter
	 * when a dashboard redraw is desired.
	 *
	 * @async
	 * @memberof CommonDashboardComponent
	 */
	@HostListener(
		AppEventConstants.redrawDashboardEvent)
	public async redrawDashboard(): Promise<void>
	{
		this.loading = true;

		this.dashboardSections = [];
		this.displayedSectionCount = 0;
		this.authorizedSectionCount = 0;
		this.dashboardDisplayComplete = false;

		await this.setupDashboard();
	}

	/**
	 * On component initialization event.
	 * This method is used to set this component for route based
	 * initialization.
	 *
	 * @memberof CommonDashboardComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.sessionUser = this.sessionService.user;

		this.subscriptions.add(
			this.scrollHeightChangedSubject.pipe(
				debounceTime(this.tabCalculationDebounceDelay),
				distinctUntilChanged())
				.subscribe(
					(scrollHeight: number) =>
					{
						this.setTabHeights(scrollHeight);
						this.emitHeaderContextContent();
					}));

		this.pageContext =
			<IDynamicComponentContext<Component, any>>
				{
					source: this,
					data: <any> {}
				};

		await this.setupDashboard();

		this.scroll();
	}

	/**
	 * On component destroy event.
	 * This method is used to reset the route strategy to it's original
	 * implementation on destroy.
	 *
	 * @memberof CommonDashboardComponent
	 */
	public ngOnDestroy(): void
	{
		this.scrollHeightChangedSubject.complete();
		this.subscriptions.unsubscribe();
	}

	/**
	 * Handles a full section widget display message sent from
	 * the child section component. This will mark the dashboard
	 * as fully displayed when all sections and widgets are
	 * displayed.
	 *
	 * @memberof CommonDashboardComponent
	 */
	public sectionComponentDisplayed(): void
	{
		if (++this.displayedSectionCount !== this.authorizedSectionCount)
		{
			return;
		}

		setTimeout(
			() =>
			{
				this.displayParameterFilter =
				!AnyHelper.isNull(this.parameterLayoutSchema);
				this.dashboardDisplayComplete = true;
				this.layoutChanged = true;

				setTimeout(
					() =>
					{
						this.layoutChanged = false;
					},
					this.siteLayoutService.debounceDelay);

				setTimeout(
					() =>
					{
						this.activeSectionItem =
							this.activeSectionItem || this.sectionItems[0];
						this.siteLayoutChanged();
						this.finishedLoading.emit();
						this.emitHeaderContextContent();

						if (!AnyHelper.isNull(
							(<any>this.entityContext?.source)
								?.changeDetectorReference))
						{
							(<any>this.entityContext.source)
								.changeDetectorReference
								.detectChanges();
						}
					},
					AppConstants.time.oneHundredMilliseconds);
			});
	}

	/**
	 * Creates an array of dashboard sections to display in this
	 * component.
	 *
	 * @async
	 * @memberof CommonDashboardComponent
	 */
	public async setupDashboard(): Promise<void>
	{
		const displayComponentContainer: IDisplayComponentContainer =
			await this.displayComponentService
				.populateDisplayComponentContainer(
					this.dashboardDisplayComponentInstanceName,
					this.pageContext);

		if (AnyHelper.isNull(displayComponentContainer))
		{
			this.handleAccessDenied();

			return;
		}

		this.displayComponentInstance =
			displayComponentContainer.container;

		if (this.displayComponentInstance.visible === false)
		{
			this.handleAccessDenied();

			return;
		}

		this.parameterLayoutData =
			this.displayComponentFactory.getParameterData(
				await this.displayComponentFactory
					.getMergedInitialParameterData(
						this.displayComponentInstance.jsonInitialParameters,
						this.pageContext,
						this.routeParameters.data));

		this.parameterLayoutSchema =
			this.displayComponentFactory
				.getParameterLayoutSchema(
					displayComponentContainer.container
						.jsonParameterDefinition,
					displayComponentContainer.container
						.jsonParameterLayout,
					this.pageContext);

		this.authorizedSectionCount =
			displayComponentContainer.container
				.displayComponentDefinition?.jsonDefinition
				.displayComponents?.length;

		// Empty but loaded and authorized dashboard.
		if (this.authorizedSectionCount === 0)
		{
			this.displayParameterFilter =
				!AnyHelper.isNull(this.parameterLayoutSchema);
			this.dashboardDisplayComplete = true;
			this.loading = false;
			this.finishedLoading.emit();
			this.emitHeaderContextContent();

			return;
		}

		this.subscriptions.add(
			displayComponentContainer.components.subscribe({
				next:
					async(dashboardSectionPromise:
						Promise<DisplayComponentInstance>) =>
					{
						await this.setupDashboardSection(
							dashboardSectionPromise);
					},
				error:
					(error: any) =>
					{
						throw error;
					},
				complete:
					() =>
					{
						setTimeout(
							() =>
							{
								if (this.authorizedSectionCount === 0)
								{
									this.handleAccessDenied(false);
								}
							},
							AppConstants.time.oneHundredMilliseconds);
					}
			}));
	}

	/**
	 * Sets up an individual dashboard section from a promised display
	 * component instance of type dashboard section.
	 *
	 * @async
	 * @param {Promise<DisplayComponentInstance>} dashboardSectionPromise
	 * A promise representing a display component instance of the type
	 * dashboard section.
	 * @memberof CommonDashboardComponent
	 */
	public async setupDashboardSection(
		dashboardSectionPromise:
			Promise<DisplayComponentInstance>): Promise<void>
	{
		const dashboardSectionInstance: DisplayComponentInstance =
			await dashboardSectionPromise;

		if (AnyHelper.isNull(dashboardSectionInstance)
			|| dashboardSectionInstance.visible === false)
		{
			this.authorizedSectionCount--;

			return null;
		}

		const associatedSection: IDashboardParameterSet =
			this.routeParameters.children?.find(
				(sectionParameterSet: IDashboardParameterSet) =>
					sectionParameterSet.identifier ===
						dashboardSectionInstance.name);

		const dashboardSection: IDashboardSection =
			this.displayComponentFactory.dashboardSection(
				dashboardSectionInstance,
				this.displayComponentFactory.getParameterData(
					await this.displayComponentFactory
						.getMergedInitialParameterData(
							dashboardSectionInstance?.jsonInitialParameters,
							this.pageContext,
							!AnyHelper.isNull(associatedSection?.data)
								? associatedSection.data
								: {})));

		const dashboardSectionSubscription: IDisplayComponentContainer =
			this.displayComponentService
				.subscribeToDisplayComponentContainer(
					dashboardSectionInstance,
					dashboardSectionInstance.jsonInterpolationData?.widgets,
					this.pageContext);

		if (AnyHelper.isNull(dashboardSectionSubscription?.container)
			|| AnyHelper.isNull(dashboardSectionSubscription?.components))
		{
			this.authorizedSectionCount--;

			return null;
		}

		let widgetCount: number = 0;
		const availableWidgetsCount: number =
			dashboardSectionInstance
				.displayComponentDefinition?.jsonDefinition
				?.widgets?.length;
		dashboardSection.authorizedWidgetCount =
			availableWidgetsCount;

		return new Promise(
			(resolve) =>
			{
				this.subscriptions.add(
					dashboardSectionSubscription.components.subscribe({
						next:
							async(dashboardWidgetPromise:
								Promise<DisplayComponentInstance>) =>
							{
								widgetCount++;
								await this.setupDashboardWidget(
									dashboardSection,
									dashboardWidgetPromise,
									associatedSection);
							},
						error:
							(error: any) =>
							{
								throw error;
							},
						complete:
							() =>
							{
								if (widgetCount === availableWidgetsCount)
								{
									if (++this.displayedSectionCount ===
										this.authorizedSectionCount)
									{
										this.loading = false;
										this.finishedLoading.emit();
										this.emitHeaderContextContent();
										// Reset for the section load handler.
										this.displayedSectionCount = 0;
										resolve();
									}
								}
							}
					}));
			});
	}

	/**
	 * Sets up an individual dashboard widget from a promised display component
	 * instance of type dashboard widget.
	 *
	 * @async
	 * @param {IDashboardSection} dashboardSection
	 * The containing dashboard section of this widget.
	 * @param {Promise<DisplayComponentInstance>} dashboardWidgetPromise
	 * A promise representing a display component instance of the type
	 * dashboard widget.
	 * @param {IDashboardParameterSet} dashboardSectionParameterSet
	 * A the set of parameters for the section containing this widget.
	 * @memberof commonDashboardComponent
	 */
	private async setupDashboardWidget(
		dashboardSection: IDashboardSection,
		dashboardWidgetPromise: Promise<DisplayComponentInstance>,
		dashboardSectionParameterSet: IDashboardParameterSet): Promise<void>
	{
		const dashboardWidgetInstance: DisplayComponentInstance =
			await dashboardWidgetPromise;

		if (AnyHelper.isNull(dashboardWidgetInstance))
		{
			this.handleRemovedWidget(dashboardSection);

			return;
		}

		const associatedWidget: IDashboardParameterSet =
			dashboardSectionParameterSet?.children?.find(
				(sectionParameterSet: IDashboardParameterSet) =>
					sectionParameterSet.identifier ===
						dashboardWidgetInstance.name);

		const translatedData: any =
			this.displayComponentFactory.getParameterData(
				await this.displayComponentFactory
					.getMergedInitialParameterData(
						dashboardWidgetInstance.jsonInitialParameters,
						this.pageContext,
						!AnyHelper.isNull(associatedWidget?.data)
							? associatedWidget.data
							: {}));

		const dashboardWidget: IDashboardWidget =
			await this.displayComponentFactory.dashboardWidget(
				dashboardWidgetInstance,
				<IDynamicComponentContext<Component, any>>
				{
					source: this.pageContext.source,
					data: translatedData?.data
				});

		if (!AnyHelper.isNull(dashboardWidget))
		{
			dashboardWidget.parameterLayoutData = translatedData;

			const sectionExists: boolean =
				this.dashboardSections.filter(
					(filterSection: IDashboardSection) =>
						filterSection.order ===
							dashboardSection.order).length > 0;

			// Only add the section when we have a widget to display
			if (sectionExists === false)
			{
				this.dashboardSections =
					[
						...this.dashboardSections,
						dashboardSection
					].sort(
						(dashboardSectionOne: IDashboardSection,
							dashboardSectionTwo: IDashboardSection) =>
							dashboardSectionOne.order
								- dashboardSectionTwo.order);

				if (!AnyHelper.isNullOrEmpty(dashboardSection.label))
				{
					this.sectionItems =
					[
						...this.sectionItems,
						<MenuItem>
						{
							label: dashboardSection.label,
							id: StringHelper.getCleanedValue(
								dashboardSection.label,
								[
									AppConstants.characters.space
								]),
							queryParams: {
								order: dashboardSection.order
							}
						}
					].sort(
						(dashboardTabOne: MenuItem,
							dashboardTabTwo: MenuItem) =>
							dashboardTabOne.queryParams.order
								- dashboardTabTwo.queryParams.order);
				}
			}

			dashboardWidget.order =
				dashboardWidgetInstance.order;

			dashboardSection.widgets =
				[
					...dashboardSection.widgets,
					dashboardWidget
				].sort(
					(dashboardWidgetOne: IDashboardWidget,
						dashboardWidgetTwo: IDashboardWidget) =>
						dashboardWidgetOne.order -
							dashboardWidgetTwo.order);
		}
		else
		{
			this.handleRemovedWidget(dashboardSection);
		}

		this.finishedLoading.emit();
	}

	/**
	 * Handle shared logic for removal of a widget from a section based
	 * on ownership, security, or invalid naming.
	 *
	 * @param {IDashboardSection} dashboardSection
	 * The dashboard section to handle the removed widget event for.
	 * @memberof CommonDashboardComponent
	 */
	private handleRemovedWidget(
		dashboardSection: IDashboardSection): void
	{
		dashboardSection.authorizedWidgetCount--;

		if (dashboardSection.authorizedWidgetCount === 0)
		{
			this.authorizedSectionCount--;

			if (this.authorizedSectionCount === 0)
			{
				this.handleAccessDenied();
			}
		}
	}

	/**
	 * Handle access denied logic which will complete the load of the dasboard
	 * or navigate to the access denied page if this is a full page dashboard.
	 *
	 * @param {boolean} showAccessDenied
	 * An optional value indicating if the access denied page should show.
	 * The default is true.
	 * @memberof CommonDashboardComponent
	 */
	private handleAccessDenied(
		showAccessDenied: boolean = true): void
	{
		if (this.fullPageDashboard === true
			&& showAccessDenied)
		{
			EventHelper.dispatchNavigateToAccessDeniedEvent(
				this.location.path(),
				this.getDashboardResources());

			return;
		}

		this.displayParameterFilter =
			!AnyHelper.isNull(this.parameterLayoutSchema);
		this.dashboardDisplayComplete = true;
		this.loading = false;
		this.finishedLoading.emit();
		this.emitHeaderContextContent();
	}

	/**
	 * Gathers available dashboard resources based on the loaded and allowed
	 * set of data. This is used to populate the access denied resources
	 * parameter.
	 *
	 * @returns {string[]}
	 * The list of possible resources that are required to display this page.
	 * @memberof CommonDashboardComponent
	 */
	private getDashboardResources(): string[]
	{
		const resources: string[] = [];

		resources.push(this.dashboardDisplayComponentInstanceName);

		const pageSections: any =
			this.displayComponentInstance
				?.displayComponentDefinition
				?.jsonDefinition
				?.displayComponents;

		if (!AnyHelper.isNull(pageSections)
			&& this.dashboardSections.length === 0)
		{
			resources.push(
				...pageSections.flatMap(
					(item: any) =>
						item.displayComponent));
		}

		if (this.dashboardSections.length > 0)
		{
			this.dashboardSections.forEach(
				(dashboardSection: IDashboardSection) =>
				{
					resources.push(
						dashboardSection.displayComponentInstance.name);
					dashboardSection
						.displayComponentInstance
						.displayComponentDefinition
						.jsonDefinition
						.widgets.forEach(
							(widget: any) =>
							{
								resources.push(
									widget.displayComponent);
							});
				});
		}

		return resources;
	}

	/**
	 * Emits the header context content.
	 *
	 * @memberof CommonDashboardComponent
	 */
	private emitHeaderContextContent()
	{
		this.headerContextContent.emit(
			{
				loading: this.loading,
				sectionItems: this.sectionItems,
				tabItems: this.tabItems,
				displayNavigationDropdown: this.displayNavigationDropdown,
				displayParameterFilter: this.displayParameterFilter,
				activeSectionItem: this.activeSectionItem,
				activeTabItem: this.activeTabItem,
				parameterLayoutData: this.parameterLayoutData,
				parameterLayoutSchema: this.parameterLayoutSchema,
				dashboardDisplayComplete: this.dashboardDisplayComplete,
				settingsActive: this.settingsActive,
				displayComponentInstance: this.displayComponentInstance,
				buttonMenuNavigationGroup: this.buttonMenuNavigationGroup,
				dashboardSections: this.dashboardSections
			});
	}
}