<div class="ui-g-6 search-filter {{siteLayoutService.contentCssClass}}"
	[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}">
	<input
		pInputText
		type="text"
		[class]="inputStyleClass"
		[formControl]="formControl"
		[formlyAttributes]="field"
		(change)="onClick($event)"
		(keypress)="onKeyPress($event)" />
	<button type="button"
		pButton
		icon="fa fa-search"
		styleClass="p-button-primary"
		(click)="onClick($event)">
	</button>
	<span *ngIf="field.props.searchTooltip != null && field.props.searchTooltip.length > 0">
		<app-common-icon-tooltip
			[content]="field.props.searchTooltip"
			[field]="field"
			[escape]="false"
			position="left"
			iconClass="search-tooltip-container fa fa-info-circle theme-color label-info-icon">
		</app-common-icon-tooltip>
	</span>
</div>